<template>
  <container>
    <div class="dev-wrap">
      <h1 class="dev-title">Страница находится в разработке</h1>
      <button class="dev-btn" @click="$router.push('/')">Вернуться в меню</button>
    </div>
  </container>
</template>

<script>
import Container from "@/components/Container/Container";

export default {
  components: {
    Container,
  },
};
</script>
<style>
html,
body,
#app,
.container {
  height: 100%;
}
.dev-title {
  text-align: center;
}
.dev-wrap {
  height: 100%;
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dev-btn {
  display: block;
  margin: 24px auto 0;
  border: none;
  padding: 8px 14px;
  color: #ffffff;
  background-color: #3ec5fc;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
</style>